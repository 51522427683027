import React, { memo, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

import { EmployeeSurvey } from '@modules/survey/types/objects';
import Card from '@common/components/card';
import Icon from '@common/components/icon';
import { Button } from '@common/components/button';

import { useAppSelector } from '@common/hooks';
import Api from '@common/services/api';
import { ApiResponse } from '@common/services/api/types';
import { StoreState } from '@common/types/store';
import { TPositionInTime, getPositionInTimeLabel, getPositionInTime } from '@common/utils/date';

import './new-survey-badge.scss';

type NewSurveyBadgeProps = {
  orgId: string;
  networkId: string;
  linkDisabled: boolean;
};

const NewSurveyBadge = memo(({ orgId, networkId, linkDisabled }: NewSurveyBadgeProps) => {
  const [survey, setSurvey] = useState<null | EmployeeSurvey>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const path = `/v1/organisations/${orgId}/users/me/next-survey`;
    Api.get<ApiResponse<EmployeeSurvey>>(path).then((response) => {
      setSurvey(response.data); // response.data might be null, no re-render in that case
    });
  }, [orgId, setSurvey]);

  const locale = useAppSelector((state: StoreState) => {
    return state.loggedUser.user.language.locale;
  });

  const expirationLabel = useMemo(() => {
    const expiresAt = survey?.settings?.expires_at;
    if (!expiresAt) return null;
    const type = getPositionInTime(expiresAt);
    return {
      highlight: type === TPositionInTime.TODAY || type === TPositionInTime.TOMORROW,
      value: getPositionInTimeLabel(expiresAt, locale, t),
    };
  }, [survey, t, locale]);

  const button = (
    <Button type="soft" size="fill" disabled={linkDisabled}>
      { t('core:new_survey_badge_cta') }
    </Button>
  );

  if (!survey) return null;

  return (
    <Card containerClassName="NewSurveyBadge">
      <Card.Content>
        <h4 className="newSurveyAvailable">{ t('core:new_survey_available') }</h4>
        <div>
          <Icon size="large" type="check_circle" isFilled color="white" />
          <div className="NewSurveyBadge__texts">
            <h4>{ survey.title }</h4>
            <p>
              <span className="NewSurveyBadge__questionsTotal">
                <Trans
                  i18nKey="common:question"
                  values={{ count: survey.question_count }}
                />
                { expirationLabel && ' • ' }
              </span>
              {
                expirationLabel && (
                  <span
                    className={`NewSurveyBadge__expirationLabel ${expirationLabel.highlight ? 'highlight' : ''}`}
                  >
                    {
                      expirationLabel.highlight ?
                        capitalize(expirationLabel.value) :
                        expirationLabel.value
                    }
                  </span>
                )
              }
            </p>
          </div>
        </div>
        {
          linkDisabled ?
            (
              <a>{ button }</a>
            ) :
            (
              <Link to={`/networks/${networkId}/survey/${survey.id}/1`}>
                { button }
              </Link>
            )
        }
      </Card.Content>
    </Card>
  );
});

export default NewSurveyBadge;
