import React from 'react';
import { Trans } from 'react-i18next';
import List from '../../../../common/components/list';
import { EventWidgetItem } from './events-widget-item';

import type { Event } from '../../../events/types';

interface Props {
  events: Event[],
  onView: () => unknown,
  onOpen: (eventId: string) => unknown,
}

export const EventsWidget = ({ events, onView, onOpen }: Props) => (
  <div className="balloon">
    <h5 className="balloon__title">
      <span><Trans i18nKey="social:upcoming_events" /></span>
      <div className="pull-right">
        <a onClick={onView}>
          <Trans i18nKey="social:view_events" />
        </a>
      </div>
    </h5>
    <List
      // @ts-expect-error
      items={events.slice(0, 3)}
      renderRow={EventWidgetItem}
      rowProps={{
        onOpen,
      }}
      placeholder={(
        <Trans i18nKey="social:no_upcoming_events" />
      )}
    />
  </div>
);
